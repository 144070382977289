// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-accommodation-js": () => import("./../src/templates/accommodation.js" /* webpackChunkName: "component---src-templates-accommodation-js" */),
  "component---src-templates-facilities-js": () => import("./../src/templates/facilities.js" /* webpackChunkName: "component---src-templates-facilities-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-byron-bay-accommodation-js": () => import("./../src/pages/byron-bay-accommodation.js" /* webpackChunkName: "component---src-pages-byron-bay-accommodation-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facilities-js": () => import("./../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-wedding-js": () => import("./../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-pages-wellness-js": () => import("./../src/pages/wellness.js" /* webpackChunkName: "component---src-pages-wellness-js" */)
}

